<template>
  <v-toolbar
    absolute
    tile
    width="100%"
    elevation="0"
    class="px-3"
    :height="height"
  >
    <v-btn
      text
      color="primary"
      class="details__back--text btn--text pl-3"
      @click="goBack"
    >
      <v-icon>
        mdi-chevron-left
      </v-icon>
      Wróć
    </v-btn>
    <v-toolbar-title class="ma-0 mt-4">
      <div>
        {{ title }}
        <div
          v-if="!isPreview && blocked"
          class="block-status"
        >
          Blokada
        </div>
      </div>
    </v-toolbar-title>
    <slot>
      <v-spacer />
      <v-btn
        class="mr-1"
        icon
        @click="goBack"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </slot>
  </v-toolbar>
</template>

<script>
import warehouseTabs from '../../../helpers/warehouseTabs'
import bdoCardsTabs from '../../../helpers/bdoCardsTabs'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    isPreview: {
      type: Boolean,
      required: false
    },
    closeSidebar: {
      type: Boolean,
      required: false
    },
    blocked: {
      type: Boolean,
      required: false
    },
    height: {
      type: String,
      default: '80px',
      required: false
    },
    tableName: {
      type: String,
      required: false
    },
    prevRoute: {
      type: Object,
      default: () => {}
    },
  },
  methods: {
    goBack () {
      if (this.isPreview && this.closeSidebar) {
        this.$emit('closeDetails')
      } else {
        const prevRouteName = this.prevRoute?.name
        const isWarehouseTable = warehouseTabs.map(tab => tab.value).includes(this.tableName)
        const isBdoCardsTable = bdoCardsTabs.map(tab => tab.value).includes(this.tableName)

        // routeId represents id of the item that is currently active in the table
        const routeId = this.$route.params.id

        // to prevent en error when routing back after refreshing page on single view (this.tabbleName is undefined) - use parentName from route meta
        const tableName = this.tableName || this.$route.meta.parentName

        const query = { activeItemId: routeId }

        if (prevRouteName === tableName) {
          this.$router.push({ name: tableName, params: { omitFetch: true }, query })
        } else if (history.length > 2) {
          // Why > 2?
          // New tab, or window, has a default page, which also counts as history
          window.history.back() // we use history API instead prevRoute object to go back to prevent loop
        } else if (isWarehouseTable) {
          this.$router.push({ name: 'warehouse', query })
        } else if (isBdoCardsTable) {
          this.$router.push({ name: 'bdoCards', query })
        } else {
          this.$router.push({ name: tableName, query })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-toolbar__title > div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
