<template>
  <div class="py-2">
    <v-data-table
      :headers="productsTableHeaders"
      :items="list"
      class="results-table"
      :items-per-page="-1"
      hide-default-footer
      fixed-header
    >
      <template #[`item.netValue`]="{ item }">
        {{ formatValue(item.netValue, 'price') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatValue } from '../../../utils/typesEnum'

const productsTableHeaders = [
  { text: 'Typ kontenera', value: 'containerType.name', sortable: false, width: 100 },
  { text: 'Typ odpadu', value: 'debrisType.displayName', sortable: false, width: 140 },
  { text: 'Kod odpadu', value: 'debrisType.code', sortable: false, width: 90 },
  { text: 'Cena netto', value: 'netValue', sortable: false, width: 90 }
]
export default {
  data() {
    return {
      productsTableHeaders
    }
  },
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  methods: {
    formatValue
  }
}
</script>
